/* eslint-disable react-hooks/exhaustive-deps */
import { React } from "react";
import { useContext, useState } from "react";
import {
  Body, HelloTextBold,
} from "../../components/common/MainCard";
import Header from "../../components/layout/header";
import NavBar from "../../components/layout/navBar";
import { useTranslation } from "react-i18next";
//import { TransactionContext } from "../../stores/TransactionStore";
import { UserContext } from "../../stores/UserStore";

import Footer from "../../components/layout/footer";
import { FlexView } from "../../components/common";

import {
  TextField,
  Grid,
  Button,
  List,
  ListItemText,
  Typography,
  Box,
  ListItem,
  Paper
} from "@mui/material"
import { useEffect } from "react";
import transactionsAPI from '../../api/transactions';

const Transaction = () => {
  const { currentUser } = useContext(UserContext);

  const [currentToken, setCurrentToken] = useState(0)
  const [toAddTokens, setToAddTokens] = useState(0)
  const [selectedCompany, setSelectedCompany] = useState()
  const [options_companies, setOptionCompanies] = useState((() => {
    let obj = {}
    Object.keys(currentUser.roles.COMPANY).filter((val) => val !== "id").forEach((val, _) => obj[val] = 0)
    return obj
  })())
  const [extract, setExtract] = useState((() => {
    const _extract = {}
    Object.keys(options_companies).forEach(c => _extract[c] = [])
    return _extract
  })())
  const { t } = useTranslation();

  //const { transactions, showHiddenInfo, schearchText, initialDate, finishDate, GetInitialTransactionData, handleSchearchTerm, handleRefreshData, ConvertTimezone, ConvertValue, ConvertMinutesToString, ShowReportAnIssue, CloseReportAnIssue, ReportAnIssue } = useContext(TransactionContext)

  const listExtract = async () => {
    const _extract = {}
    Object.keys(options_companies).forEach(c => {
      _extract[c] = []
      options_companies[c] = 0
    })
    await transactionsAPI.listTransactions()
      .then(result => {
        result
          .filter(trans => Object.keys(options_companies).includes(trans.Customer))
          .forEach(trans => {
            options_companies[trans.Customer] += trans.NewBudget - trans.OldBudget
            _extract[trans.Customer].push(trans)
          });
      })
      .catch(err => {
        console.log("Error Listing Transactions:")
        console.log(err)
      })
    setExtract(_extract)
  }

  useEffect(async () => {
    await listExtract()
  }, [])

  useEffect(() => {
    if (!Object.keys(options_companies).includes(selectedCompany)) return;
    options_companies[selectedCompany] = currentToken
    setOptionCompanies(options_companies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken])

  useEffect(() => {
    if (selectedCompany !== null || selectedCompany !== "" || selectedCompany !== undefined) {
      setCurrentToken(options_companies[selectedCompany])
    }
  }, [extract])

  useEffect(() => {
    setCurrentToken(options_companies[selectedCompany])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany])

  const tokensChange = (e) => {
    //Added this validation because the field accepts `+`, `-` and `e` values alongside numbers
    const numbersRegex = /[^0-9]/g
    const value = e.target.value
    const validatedValue = value.replace(numbersRegex, '')

    if (validatedValue !== '') {
      setToAddTokens(validatedValue)
    } else {
      setToAddTokens(0)
    }
  }

  const addTokens = async () => {
    const newBudget = options_companies[selectedCompany] + parseInt(toAddTokens)
    if (toAddTokens > 0) {
      const payload = {
        operation: 'Add Budget',
        oldBudget: `${options_companies[selectedCompany]}`,
        newBudget: `${newBudget}`,
        customer: selectedCompany,
        user: `${currentUser.firstName} ${currentUser.lastName}`
      }

      await transactionsAPI.insertTransaction(payload)
        .then(_ => {
          listExtract()
        })
        .catch(err => {
          console.log("Error Inserting transaction:")
          console.log(err)
        })
    }

    //setCurrentToken(newBudget)
  }

  // const testTransactionList = async () => {
  //   const response = await transactions.listTransactions()

  //   console.log(response)
  // }

  const list_transactions = () => {
    if (selectedCompany)
      return (
        <Box>
          <Box sx={{ flexDirection: "row", alignItems: "center", justifyContent: "space-around", justifyItems: "space-around", display: "flex", padding: "10px" }}>
            <TextField type='number' variant="outlined" onChange={tokensChange} label="Tokens" />
            <Button variant="contained" onClick={async () => await addTokens()}>Add Tokens</Button>
          </Box>
          <Box sx={{ height: "57vh", overflowY: "scroll", overflow: "auto", alignItems: "start", justifyContent: "start", display: "flex", marginLeft: "170px" }}>
            <List sx={{ overflowY: "auto" }}>
              {extract[selectedCompany].map(item => (
                <ListItemText primaryTypographyProps={{ fontWeight: "bold", color: item.Valor < 0 ? "red" : "green" }}>
                  {`${item.Operation} | ${item.NewBudget - item.OldBudget} Tokens | by ${item.User} on ${item.Date.split(" ").join(" at ")}`}
                </ListItemText>
              ))}
            </List>
          </Box>
        </Box>
      )
    else
      return <FlexView></FlexView>
  }

  const hello_text = () => <HelloTextBold>{t("LandingPage.Hello")} {currentUser?.firstName ?? "NAO TEM"}</HelloTextBold>

  const budget = () => (selectedCompany)
    ? ([
      <Typography margin="8px" fontSize="1.5rem" fontWeight="normal" color="black">{"Total budget of "}</Typography>,
      <Typography margin="8px" fontSize="1.5rem" color="#172983" fontWeight="bolder">{selectedCompany}</Typography>,
      <Typography margin="8px" fontSize="1.5rem" fontWeight="normal" color="black">{" is "}</Typography>,
      <Typography margin="8px" fontSize="1.5rem" color="#172983" fontWeight="bolder">{currentToken}</Typography>,
      <Typography margin="8px" fontSize="1.5rem" fontWeight="normal" color="black">{" tokens"}</Typography>
    ])
    : [<Typography margin="8px" fontSize="1.5rem" fontWeight="normal" color="black">Nenhuma compania selecionada</Typography>]

  return (
    <Body>
      <Header />
      <NavBar />
      <FlexView height="100%" style={{ overflowY: "scroll", padding: "30px" }}>
        <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }} direction="column" height="100%">
          <Grid item xs={6}>
            {hello_text()}
            <Paper sx={{ margin: "10px", padding: "10px" }}>
              <Typography sx={{ color: "gray" }}>
                Empresas:
              </Typography>
              <List>
                {Object.keys(options_companies).map(o => <ListItem margin="10px" padding="10px"> <Button sx={{ color: selectedCompany === o ? "primary" : "black" }} onClick={() => setSelectedCompany(o)}>{o}</Button> </ListItem>)}
              </List>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Box sx={{ flexDirection: "row", display: "flex", justifyContent: "center", flexWrap: "wrap", margin: "10px" }}>
              {budget()}
            </Box>
            {list_transactions()}
          </Grid>
        </Grid>
      </FlexView>
      <Footer />
    </Body>
  );
};

export default Transaction;
